import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@oriola-origo/core/lib/Button";
import Box from "@oriola-origo/core/lib/Box";
import OriolaThemeProvider from "@oriola-origo/core/lib/Theme/OriolaThemeProvider";

export enum PreDefinedFilter {
  AssortmentSuppliers = "AssortmentSuppliers",
}

export interface PreDefinedFiltersProps {
  onClick: (filter: PreDefinedFilter) => void;
}

export const PREDEFINED_FILTERS: Record<PreDefinedFilter, string> = {
  [PreDefinedFilter.AssortmentSuppliers]: "selectAssortmentSuppliers",
};

export default function PreDefinedFilters({
  onClick,
}: Readonly<PreDefinedFiltersProps>) {
  const { t } = useTranslation();
  return (
    <OriolaThemeProvider>
      <Box mt={2}>
        {Object.entries(PREDEFINED_FILTERS).map(([key, localeKey]) => (
          <Button
            key={key}
            color="secondary"
            variant="text"
            onClick={() => onClick(key as PreDefinedFilter)}
          >
            {t(localeKey)}
          </Button>
        ))}
      </Box>
    </OriolaThemeProvider>
  );
}
